import { Routes, Route } from "react-router-dom";
import Header from "./components/Header";
import Login from "./pages/Login";
import Home from "./pages/Home";
import "./App.css";
import Details from "./pages/Details";
import Instruction from "./pages/Instruction";

function App() {
  return (
    <>
      <Header />
      <Routes>
        
        <Route path="/" element={<Home />} />
        <Route path="/login" element={<Login />} />
        <Route path="/instruction" element={<Instruction />} />
        <Route path="/details/:uid" element={<Details />} />
      </Routes>
    </>
  );
}

export default App;
