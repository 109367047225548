import { Box, List, ListItem, ListItemText, Typography } from "@mui/material";
import React from "react";

const Instruction = () => {
    const instructionHeading = {
        "@media (max-width: 780px)": {
          fontSize: "1.5rem ",
        },
      };
      
  return (
    <>
      <Box>
        <Typography
          sx={{
            textAlign: "left",
            fontWeight: "800",
            fontSize: "1.8rem",
            color: "#fffffff0",
            marginTop: 2,
            paddingLeft: "35px",
            paddingBottom: "15px",
            ...instructionHeading,
          }}
        >
          Delete Account Instructions:
        </Typography>
        <Box display={"flex"} justifyContent={"left"} paddingLeft={"45px"}>
          <List sx={{ listStyle: "decimal", pl: 4 }}>
            <ListItem
              sx={{ display: "list-item", color: "#fff", fontSize: "1.5rem" }}
            >
              <ListItemText
                primaryTypographyProps={{ sx: { fontSize: "1.5rem" } }}
                primary="Open the app.
"
              />
            </ListItem>
            <ListItem
              sx={{ display: "list-item", color: "#fff", fontSize: "1.5rem" }}
            >
              <ListItemText
                primaryTypographyProps={{ sx: { fontSize: "1.5rem" } }}
                primary="Navigate to the Profile tab.
"
              />
            </ListItem>
            <ListItem
              sx={{ display: "list-item", color: "#fff", fontSize: "1.5rem" }}
            >
              <ListItemText
                primaryTypographyProps={{ sx: { fontSize: "1.5rem" } }}
                primary="Select Delete Account, a popup will appear for your confirmation to delete the account and all the data associated with you.
"
              />
            </ListItem>
            <ListItem
              sx={{ display: "list-item", color: "#fff", fontSize: "1.5rem" }}
            >
              <ListItemText
                primaryTypographyProps={{ sx: { fontSize: "1.5rem" } }}
                primary="Once confirmed, your account will be marked for deletion, and it will be permanently deleted.
"
              />
            </ListItem>
          </List>
        </Box>
      </Box>
    </>
  );
};

export default Instruction;
