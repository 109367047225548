import React, { useEffect, useState } from "react";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { getFirestore, getDocs, query, collection } from "firebase/firestore";
import { Box, Card, Grid, Typography } from "@mui/material";
import FolderIcon from "@mui/icons-material/Folder";
import config from "../configuration";
import { useNavigate } from "react-router-dom";
const Home = ( { parms } ) =>
{
  const auth = getAuth(config);
  const db = getFirestore(config);
  const navigate = useNavigate();
  const [usersData, setUsersData] = useState([]);
  

    useEffect(() => {
    (async () => {
      await checkUser();
    })();
  }, []);
  
  const checkUser = async () => {
    onAuthStateChanged(auth, async (user) => {
      if (user) {
        // const uid = user.uid;
        const q = query(collection(db, "users"));
        const querySnapshot = await getDocs(q);
        const userData = querySnapshot.docs.map((doc) => {
          const docID= doc?.id;
          const data = doc.data();
          // console.log('userDatauserData',data)
          // Check if storagePath is defined
          console.log('iddd',data);
          if (docID) {
            //const splitLink = data.storagePath.split("_");
            // Extract the part after the last underscore and remove any trailing slashes
            //const uid =
            //  splitLink.length > 1 ? splitLink[1].replace(/\/$/, "") : "";
            return {
              ...data,
              uid: docID,
            };
          } else {
            // Handle the case where storagePath is undefined or null
            return {
              ...data,
              uid: "", // or handle as appropriate
            };
          }
        });
        userData.sort((a, b) => {
          let folderA = `${a.companyName || ''}/${a.firstName || ''}`;
          let folderB = `${b.companyName || ''}/${b.firstName || ''}`;
          
          return folderA.toLowerCase().localeCompare(folderB.toLowerCase());
        });
        setUsersData(userData);
      } else {
        navigate("/login");
      }
    });
  };
  return (
    <Grid
    container spacing={1}
      sx={{
        paddingTop: 3,
        paddingBottom:3,
        display: "flex",
        justifyContent: "center;",
        flexWrap: "wrap",
      }}
    >
      {usersData &&
        usersData.map((user, index) => (
          
          <Card
            key={index}
            className="user-card"
            sx={{
              margin:2,
              width: "100%",
              maxWidth: { md: "180px", xs: "130px" },
              padding: 1,
              textAlign: "center",
              backgroundColor: "#014B8D",
              boxShadow:
                "rgb(0 0 0 / 15%) 0px 3px 6px, rgb(0 0 0 / 32%) 0px 3px 6px",
            }}
            onClick={() =>
              navigate(`/details/${user.uid}`, {
                state: {
                  uid: user.uid,
                  heading: `${user.companyName}/${user.firstName}`,
                },
              })
            }
          >
            <FolderIcon
              sx={{
                fontSize: "5rem",
                color: "#FFC801",
              }}
            />
            <Typography
              sx={{
                fontSize: "0.8rem",
                color: "#fff",
              }}
            >
              {`${user.companyName}/${user.firstName}`}
            </Typography>
          </Card>
        ))}
    </Grid>
  );
};
export default Home;