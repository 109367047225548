import React from "react";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Container from "@mui/material/Container";
import { Box, Button, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { signOut, getAuth } from "firebase/auth";
import config from "../configuration";
import LogoIcon from "../assets/images/tlmgIcon.png";
const login = localStorage.getItem("login");
const Header = () => {
  const pathName = window.location.pathname;
  const auth = getAuth(config);
  const navigate = useNavigate();

  const handleLogout = () => {
    signOut(auth)
      .then(() => {
        // Sign-out successful.
        navigate("/login");
        console.log("Signed out successfully");
      })
      .catch((error) => {
        // An error happened.
      });
  };

  const Image = {
    "@media (max-width: 780px)": {
      width: "55%  !importent",
    },
  };

  return (
    <AppBar
      position="static"
      sx={{ backgroundColor: "#014b8d", borderBottom: "2px solid white" }}
    >
      <Container maxWidth="xxl">
        <Toolbar
          disableGutters
          sx={{
            display: "flex",
            justifyContent: "space-between",
            paddingTop: "15px",
            paddingBottom: "15px",
          }}
        >
          <Box
            sx={
              pathName === "/instruction"
                ? { width: { md: "25%", xs: "100%" } }
                : { width: { md: "20%", xs: "40%" } }
            }
          >
            <img
              src={require("../assets/images/top_icon.png")}
              width={"100%"}
            />
          </Box>

          <Box
            sx={
              pathName === "/login" ? { display: "none" } : { display: "block" }
            }
          >
            <Box
              sx={
                pathName === "/instruction"
                  ? { display: "none" }
                  : { display: "block" }
              }
            >
              <Button
                variant="outlined"
                onClick={() => navigate("/")}
                sx={{
                  marginRight: "20px",
                  border: "1px solid white ",
                  background: "white",
                  fontWeight: "bold",
                }}
              >
                Home
              </Button>
              <Button
                variant="outlined"
                onClick={() => handleLogout()}
                sx={{
                  border: "1px solid white ",
                  background: "white",
                  fontWeight: "bold",
                }}
              >
                Logout
              </Button>
            </Box>
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
};

export default Header;
