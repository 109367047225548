import React, { useEffect, useState } from "react";
import {
  Box,
  Card,
  Typography,
  Dialog,
  DialogContent,
  IconButton,
  Checkbox,
  Button,
} from "@mui/material";
import { useLocation } from "react-router-dom";
import { saveAs } from "file-saver";
import { getFirestore, getDoc, doc } from "firebase/firestore";
import { getStorage, ref, getMetadata } from "firebase/storage";
import config from "../configuration";
import DownloadIcon from "@mui/icons-material/Download";
import CloseIcon from "@mui/icons-material/Close";
import CircularProgress from "@mui/material/CircularProgress";
const Details = () => {
  const db = getFirestore(config);
  const storage = getStorage(config);
  const location = useLocation();

  const [data, setData] = useState([]);

  const [heading, setHeading] = useState("");
  const [open, setOpen] = useState(false);
  const [image, setImage] = useState("");

  const [imageName, setImageName] = useState("");
  const [description, setDescription] = useState("");
  const [selectedData, setSelectedData] = useState([]);
  const [mediaTypeUrl, setMediaTypeUrl] = useState("");
  const [isCheckData, setisCheckData] = useState(false);

  useEffect(() => {
    if (location.state) {
      location.state.heading && setHeading(location.state.heading);
      fetchData(location.state.uid);
    }

    setTimeout(() => {
      if (data.length === 0) {
        setisCheckData(true);
      }
    }, "5000");
  }, []);
  const fetchData = async (uid) => {
    const imageRef = doc(db, "Store", uid);
    const userImageData = await getDoc(imageRef);

    if (userImageData.exists()) {
      const photos = userImageData.data().ListOfPhotos;

      const photosWithMediaType = await Promise.all(
        photos.map(async (item) => {
          const type = await getMediaType(item?.photoUrl);
          return { ...item, mediaType: type };
        })
      );

      setData(photosWithMediaType);
    } else {
      console.log("No such document!");
    }
  };

  const getMediaType = async (path) => {
    try {
      const decodedPath = decodeURIComponent(path);
      const storageRef = ref(storage, decodedPath);
      const metadata = await getMetadata(storageRef);
      return metadata.contentType;
    } catch (error) {
      console.error("Error fetching metadata:", error);
    }
  };

  const downloadImage = async (data) => {
    try {
      const items = Array.isArray(data) ? data : [data];

      for (const item of items) {
        if (item.photoUrl && item.mediaType) {
          await downloadFile(item.photoUrl, item.mediaType);
        } else {
          console.warn("Item is missing required properties:", item);
        }
      }
    } catch (error) {
      console.error("Error downloading files:", error);
    }
  };

  const downloadFile = async (url, mediaType) => {
    try {
      const response = await fetch(url);
      const blob = await response.blob();

      let fileName = "";
      if (
        mediaType?.startsWith("image/") ||
        url.includes(".png") ||
        url.includes(".jpg")
      ) {
        fileName = `${heading}_image_${Date.now()}.jpg`;
      } else if (mediaType?.startsWith("video/") || url.includes(".mp4")) {
        fileName = `${heading}_video_${Date.now()}.mp4`;
      } else {
        fileName = `${heading}_file_${Date.now()}`;
      }

      saveAs(blob, fileName);
      console.log(`File downloaded: ${fileName}`);
    } catch (error) {
      console.error("Error downloading file:", error);
    }
  };

  const handleSelect = (item) => {
    setSelectedData((prevSelectedData) => {
      const isDataExist = prevSelectedData.some(
        (data) => data.fileName === item.fileName
      );

      if (isDataExist) {
        return prevSelectedData.filter(
          (data) => data.fileName !== item.fileName
        );
      } else {
        return [...prevSelectedData, item];
      }
    });
  };

  const handleOpen = (item) => {
    setImage(item.photoUrl);
    setImageName(item);
    setDescription(item.description);
    setMediaTypeUrl(item?.mediaType);
    setOpen(true);
  };

  const handleClose = () => setOpen(false);

  const styleDescription = {
    display: "-webkit-box",
    WebkitLineClamp: 1,
    WebkitBoxOrient: "vertical",
    overflow: "hidden",
    color: "#fff",
  };

  return (
    <>
      <Box>
        {selectedData.length > 0 && (
          <Box mt={2} display={"flex"} justifyContent={"right"} pr={3}>
            <Button
              variant="outlined"
              onClick={() => downloadImage(selectedData)}
              sx={{
                border: "1px solid white ",
                background: "white",
                fontWeight: "bold",
              }}>
              Downloade Selected
            </Button>
          </Box>
        )}

        <Typography
          sx={{
            textAlign: "center",
            fontWeight: "800",
            fontSize: "1.3rem",
            color: "#fffffff0",
            marginTop: 1,
          }}>
          {heading}
        </Typography>
        <Box
          sx={{
            padding: 3,
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "center",
            gap: 4,
          }}>
          {data.length > 0 ? (
            data.map((item) => {
              return (
                <div
                  key={item.fileName}
                  style={{
                    position: "relative",
                  }}>
                  <Checkbox
                    sx={{
                      position: "absolute",
                      background: "#fff",
                      borderRadius: 0,
                      top: 5,
                      left: 5,
                      opacity: 1,
                      zIndex: 9,
                      padding: 0,
                      borderRadius: "5px",
                    }}
                    onChange={(e) => handleSelect(item)}
                  />

                  <DownloadIcon
                    onClick={() => downloadImage(item)}
                    sx={{
                      position: "absolute",
                      top: 10,
                      right: 10,
                      color: "#fff",
                      fontWeight: "400",
                      zIndex: "111",
                    }}
                  />
                  <Card
                    className="user-card"
                    sx={{
                      transition: "0.3s",
                      width: "100%",
                      maxWidth: { md: "180px", xs: "130px" },
                      padding: 1,
                      textAlign: "center",
                      backgroundColor: "#014b8d",
                      boxShadow:
                        "rgb(0 0 0 / 15%) 0px 3px 6px, rgb(0 0 0 / 32%) 0px 3px 6px",
                    }}
                    onClick={() => handleOpen(item)}>
                    <Box
                      position={"relative"}
                      sx={{ width: "180px", height: "180px" }}>
                      {item.mediaType?.startsWith("image/") ? (
                        <img
                          src={item.photoUrl}
                          style={{
                            width: "100%",
                            height: "180px",
                            borderRadius: "5px",
                            aspectRatio: "1.5",
                          }}
                        />
                      ) : (
                        <video width="100%" height="180" controls>
                          <source src={item.photoUrl} type="video/mp4" />
                          <source src={item.photoUrl} type="video/ogg" />
                        </video>
                      )}
                    </Box>
                    <Typography sx={styleDescription}>
                      {item.description}
                    </Typography>
                  </Card>
                </div>
              );
            })
          ) : (
            <Typography
              sx={{
                textAlign: "center",
                fontWeight: "800",
                fontSize: "1.3rem",
                color: "#fffffff0",
                marginTop: 2,
              }}>
              <Box sx={{ display: "flex", justifyContent: "left" }}>
                {!isCheckData ? (
                  <CircularProgress color="inherit" />
                ) : (
                  <Typography
                    sx={{
                      textAlign: "center",
                      fontWeight: "800",
                      fontSize: "1.5rem",
                      color: "#fffffff0",
                      marginTop: 1,
                    }}>
                    No Data Found
                  </Typography>
                )}
              </Box>
            </Typography>
          )}
        </Box>
      </Box>
      <Dialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}>
        <IconButton
          aria-label="close"
          onClick={() => downloadImage(imageName, mediaTypeUrl)}
          sx={{
            position: "absolute",
            right: 45,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}>
          <DownloadIcon />
        </IconButton>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}>
          <CloseIcon />
        </IconButton>

        <DialogContent
          sx={{
            marginTop: 6,
          }}>
          {mediaTypeUrl?.startsWith("image/") ? (
            <img
              src={image}
              style={{
                width: "100%",
              }}
            />
          ) : (
            <video width="100%" height="100%" controls>
              <source src={image} type="video/mp4" />
              <source src={image} type="video/ogg" />
            </video>
          )}

          <Typography
            gutterBottom
            sx={{
              textAlign: "center",
            }}>
            {description}
          </Typography>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default Details;
