import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { getAuth, signInWithEmailAndPassword } from "firebase/auth";
import { getFirestore, getDoc, doc } from "firebase/firestore";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import {
  Container,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  TextField,
  Snackbar,
  Alert,
} from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import LoadingButton from "@mui/lab/LoadingButton";
import config from "../configuration";

const Login = () => {
  const auth = getAuth(config);
  const db = getFirestore(config);
  const navigate = useNavigate();

  const vertical = "top";
  const horizontal = "center";

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [open, setOpen] = useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleClose = () => {
    setOpen(false);
  };

  const onHandleChange = (value, field) => {
    if (value && field === "email") {
      setEmail(value);
    } else if (value && field === "password") {
      setPassword(value);
    }
  };

  const handleSubmit = () => {

    if (email.length > 0 && password.length > 0) {
      console.log("...",email,password, auth)
      setLoading(true)
      signInWithEmailAndPassword(auth, email, password)
        .then((userCredential) => {
          // Signed in
          const user = userCredential.user;
          if (user) {
            checkAdmin(user.uid);
          }
          // ...
        })
        .catch((error) => {
          setOpen(true);
          setErrorMessage("Invalid Email or Password");
          setLoading(false)
        });
    } else {
      setOpen(true);
      setErrorMessage("Please provide Email and Password");
      setLoading(false)
    }
  };

  const checkAdmin = async (uid) => {
    const userRef = doc(db, "admin", uid);
    const userData = await getDoc(userRef);

    if (userData.exists() && userData.data().admin) {
      setLoading(false)
      navigate("/");
    } else {
      setOpen(true);
      setErrorMessage("Only Admin is allowed");
      setLoading(false)
    }
  };

  return (
    <>
      <Container className="login-container">
        <Card sx={{ boxShadow: "rgb(0 0 0) 0px 5px 15px", borderRadius: 3 }}>
          <CardContent sx={{ marginTop: 5, marginBottom: 2.5 }}>
            <TextField
              id="outlined-basic"
              label="Email"
              variant="outlined"
              fullWidth
              onChange={(event) => onHandleChange(event.target.value, "email")}
            />
            <FormControl sx={{ marginTop: 2 }} variant="outlined" fullWidth>
              <InputLabel htmlFor="outlined-adornment-password">
                Password
              </InputLabel>
              <OutlinedInput
                id="outlined-adornment-password"
                type={showPassword ? "text" : "password"}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      edge="end"
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                }
                label="Password"
                onChange={(event) =>
                  onHandleChange(event.target.value, "password")
                }
              />
            </FormControl>
            <LoadingButton
              loading={loading}
              variant="contained"
              sx={{ marginTop: 3 }}
              fullWidth
              onClick={() => handleSubmit()}
            >
              LOGIN
            </LoadingButton>
          </CardContent>
        </Card>
      </Container>

      <Snackbar
        anchorOrigin={{ vertical, horizontal }}
        open={open}
        autoHideDuration={6000}
        onClose={handleClose}
      >
        <Alert
          onClose={handleClose}
          severity="error"
          variant="filled"
          sx={{ width: "100%" }}
        >
          {errorMessage}
        </Alert>
      </Snackbar>
    </>
  );
};

export default Login;
