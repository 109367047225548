import { initializeApp } from "firebase/app";

const firebaseConfig = {
  apiKey: "AIzaSyAdUdYpz0N5CBoZHWS03vSvXM5EjUnSQjs",
  authDomain: "tlmg-app-2d964.firebaseapp.com",
  databaseURL: "https://tlmg-app-2d964-default-rtdb.firebaseio.com",
  projectId: "tlmg-app-2d964",
  storageBucket: "tlmg-app-2d964.appspot.com",
  messagingSenderId: "881804532923",
  appId: "1:881804532923:web:8486c6cfeb71e7fb461d9e",
  measurementId: "G-GPE3664JLP"

};
// Initialize Firebase
const config = initializeApp(firebaseConfig);

export default config;
